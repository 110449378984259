import { getAction } from '@/api/manage'
import { getLocalStorage, setLocalStorage } from '@/utils/localStorage'
import { FRIENDLYLINKS } from '@/store/mutation-types'
import { request } from '@/utils/axios'

/**
 * 获取所有课程分类，返回的res.treeData为树形结构
 * @returns {Promise<res>}
 */
export async function getFriendlyLinksAll() {
  let res = {}

  // 优先从缓存中读取字典配置
  res.result = getLocalStorage(FRIENDLYLINKS)
  if (res.result) {
    res.success = true
    return Promise.resolve(res)
  }

  // 后台去除鉴权后换成getAction
  // res = await getAction(`/api/v1/commonpart/friendlyLink/all`)
  res = await request({
    url: `/api/v1/dms/friendshipLink/all`,
    method: 'get',
    verifyToken: true
  })


  if (!res.success) {
    return Promise.resolve(res)
  }

  res.result = res.list

  // 存到缓存里
  setLocalStorage(FRIENDLYLINKS, res.result, 3600 * 24)
  return Promise.resolve(res)
}
