<template>
  <div>
    <div class="user" @mouseenter="showMenu" @mouseleave="hideMenu">
      <el-avatar :src="avatar">
        <img src="~@/assets/index/userHeader.jpg" alt="">
      </el-avatar>
      <div class="user_info">
        <ul class="u_info">
          <li>
            <router-link :to="{name: 'account-course'}">我的课程</router-link>
          </li>
          <li>
            <router-link :to="{name: 'account-collect'}">我的收藏</router-link>
          </li>
<!--          <li>-->
<!--            <router-link :to="{name: 'account-base'}">个人信息</router-link>-->
<!--          </li>-->
<!--          <li><a href="javascript:" @click="changePassWord">修改密码</a></li>-->
          <li><a href="javascript:" @click="signOut">安全退出</a></li>
        </ul>
      </div>
    </div>
    <change-pass-word ref="passWord"></change-pass-word>
  </div>
</template>
<script>
  import { mapActions, mapState } from 'vuex'
  import ChangePassWord from './modules/ChangePassWord'

  export default {
    name: 'PAccountHeader',
    components: { ChangePassWord },
    computed: {
      ...mapState({
        // 头像
        avatar: (state) => state.user.avatar
      })
    },
    data() {
      return {
        loginUrl: process.env.VUE_APP_LOGIN_URL
      }
    },
    methods: {
      ...mapActions(['Logout']),
      showMenu() {
        this.menuShow = true
      },
      changePassWord() {
        // 获取当前用户名
        // 调用修改密码组件的展示方法并将用户名传入
        this.$refs.passWord.show()
      },
      hideMenu() {
        this.menuShow = false
      },
      signOut() {
        this.$confirm('您的账号将退出登录, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          //  退出
          this.Logout().then(() => {
            // this.$router.push({ path: '/bayareacertification/index' })
            window.location.href = this.loginUrl
            this.$message.success('已成功退出登录！')
          }).catch((err) => {
            this.$message.error('操作失败：' + err.message)
          })
        })
      }
    }
  }
</script>
<style lang="less" scoped>
@import "~@/assets/styles/global.less";

.user {

  img {
    height: 46px;
    width: 40px;
    border-radius: 50%;
    margin-top: -5px;
    cursor: pointer;
  }

  &:hover {
    .user_info {
      display: block;
    }
  }
}


.user_info {
  position: relative;
  display: none;

  .u_info {
    position: absolute;
    top: 0;
    right: -54px;

    z-index: 1000;
    width: 136px;
    margin: 0;
    padding: 0 0 10px;
    background: #fff;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0 0 10px 1px #ccc;

    li {
      width: 100px;
      font-size: 14px;
      margin: 14px auto;
      text-align: center;
      padding: 10px;
      float: none;
      border-bottom: 1px solid #ddd;

      &:hover {
        a {
          color: #578CEF;
        }
      }
    }
  }

}
</style>
