import { getAction } from '@/api/manage'
import { getLocalStorage, setLocalStorage } from '@/utils/localStorage'
import { RECORD_NUMBER } from '@/store/mutation-types'
import { request } from '@/utils/axios'

/**
 * 获取所有课程分类，返回的res.treeData为树形结构
 * @returns {Promise<res>}
 */
export async function getRecordNumberAll() {
  let res = {}

  // 优先从缓存中读取字典配置
  res.result = getLocalStorage(RECORD_NUMBER)
  if (res.result) {
    res.success = true
    return Promise.resolve(res)
  }

  // 后台去除鉴权后换成getAction
  // res = await getAction(`/api/v1/website/platformConfiguration`)
  res = await request({
    url: `/api/v1/website/platformConfiguration`,
    method: 'get',
    verifyToken: true
  })

  if (!res.success) {
    return Promise.resolve(res)
  }

  res.result = res.data

  // 存到缓存里
  setLocalStorage(RECORD_NUMBER, res.result, 3600 * 24)
  return Promise.resolve(res)
}
