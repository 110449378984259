/*
 * @Description:
 * @Version: 1.0
 * @Autor: hekai
 * @Date: 2021-07-09 16:25:50
 * @LastEditors: hekai
 * @LastEditTime: 2021-07-09 16:27:29
 */
import { request } from '@/utils/axios'

export function editUserInfo(parameter) {
  return request({
    url: '/app/v1/user',
    method: 'put',
    data: parameter,
    verifyToken: true
  })
}

export function resetPassword(parameter) {
  return request({
    url: '/app/v1/user/resetPassword',
    method: 'post',
    data: parameter,
    verifyToken: true
  })
}

