<template>
  <el-dialog
    title="修改密码"
    :visible.sync="dialogVisible"
    v-loading="loading"
    width="600px"
    :modal-append-to-body="false"
    class="modal-box">
    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
      <el-form-item label="原密码" prop="pass">
        <el-input type="password" v-model="ruleForm.pass" autocomplete="off" :clearable="true"></el-input>
      </el-form-item>
      <el-form-item label="新密码" prop="newpass">
        <el-input type="password" v-model="ruleForm.newpass" autocomplete="off" :clearable="true"></el-input>
      </el-form-item>
      <el-form-item label="确认密码" prop="checkPass">
        <el-input type="password" v-model="ruleForm.checkPass" autocomplete="off" :clearable="true"></el-input>
      </el-form-item>
      <el-form-item>
        <div class="center">
          <el-button type="primary" @click="submitForm('ruleForm')">提交</el-button>
          <el-button @click="resetForm">重置</el-button>
        </div>
      </el-form-item>
    </el-form>
    <!--    <span slot="footer" class="dialog-footer">-->
    <!--    <el-button @click="dialogVisible = false">取 消</el-button>-->
    <!--    <el-button type="primary" @click="dialogVisible = false">确 定</el-button>-->
    </span>
  </el-dialog>
</template>

<script>
  import { resetPassword } from '@api/user'
  import { nextTick } from 'vue'

  export default {
    name: 'ChangePassWord',
    data() {
      const validatePass = (rule, value, callback) => {
        if (value === '') {
          callback(new Error('请输入原密码'))
        } else {
          // 检测是不是和原密码相同
          callback()
        }
      }
      const validateNewPass = (rule, value, callback) => {
        if (value === '') {
          callback(new Error('请输入新密码'))
        } else {
          if (this.ruleForm.checkPass !== '') {
            this.$refs.ruleForm.validateField('checkPass')
          }
          callback()
        }
      }
      const validatePass2 = (rule, value, callback) => {
        if (value === '') {
          callback(new Error('请再次输入密码'))
        } else if (value !== this.ruleForm.newpass) {
          callback(new Error('两次输入密码不一致!'))
        } else {
          callback()
        }
      }
      return {
        dialogVisible: false,
        ruleForm: {
          pass: '',
          newpass: '',
          checkPass: ''
        },
        loading: false,
        rules: {
          pass: [
            { validator: validatePass, trigger: 'blur' }
          ],
          newpass: [
            { validator: validateNewPass, trigger: 'blur' }
          ],
          checkPass: [
            { validator: validatePass2, trigger: 'blur' }
          ]
        }
      }
    },
    methods: {
      submitForm(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.loading = true
            let params = {
              'confirmPassword': this.ruleForm.checkPass,
              'newPassword': this.ruleForm.newpass,
              'oldPassword': this.ruleForm.pass
            }

            resetPassword(params).then((res) => {
              if (res.success) {
                this.$message.success('修改成功！')
                this.close()
              } else {
                this.$message.error(res.message)
              }
            }).finally(() => {
              this.loading = false
            })
          }
        })
      },
      resetForm() {
        this.$refs.ruleForm.resetFields()
      },
      show() {
        this.dialogVisible = true
        this.$nextTick(() => {
          this.resetForm()
        })
      },
      close() {
        this.dialogVisible = false
      }
    }
  }
</script>

<style lang="less" scoped>
.modal-box {
  margin-top: 45px;

  .center {
    margin-left: -100px;
    display: flex;
    justify-content: center;
  }
}
</style>
