<template>
  <div class="base-layout">
    <div class="fixed" id="navbar">
      <div class="head">
        <div class="logo" @click="routerTo('/bayareacertification/index')"></div>
        <div class="navBar">
          <!--          :class="{active:activePath===item.path}"-->
          <span
            class="bar-item"
            :class="{active:activePath.includes(item.path)}"
            v-for="item in paths"
            :key="item.path"
            @click="routerTo(item.path)">
            {{ item.title }}
          </span>
        </div>
        <div class="change-text">
          <el-dropdown trigger="click" @command="changeLanguage">
            <span>
               <img src="~@/assets/index/change-text.png" alt="">
            <span>繁简切换</span>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="s">简体中文</el-dropdown-item>
              <el-dropdown-item command="t">繁體中文</el-dropdown-item>
              <!--              <el-dropdown-item command="e">英文（English）</el-dropdown-item>-->
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>
    </div>

    <div class="container">
      <router-view></router-view>
    </div>
    <div class="bottom">
      <el-container>
        <div class="footer-wrap">
          <div class="footer-main">
            <div class="main-info">
              <div style="margin-right: 68px;">
                <div class="mb-20 c-white f-20">联系我们</div>
                <div class="item-col fl-sb aic">
                  <div style="width: 280px;">{{ webData.unitName }}</div>
                  <div style="width: 500px;">地址：{{ webData.unitAddress }}</div>
                </div>
                <div class="item-col fl-sb aic">
                  <div style="width: 280px;"><span>联系人：{{ webData.contactPerson }}</span>
                  </div>
                  <div style="width: 500px;">
                    <span>联系方式：{{ webData.contactInfo }}</span>
                  </div>
                </div>
                <div class="pt-10 item-col">
                  <div><a href="http://www.cnca.gov.cn/" target="_blank">国家认证认可监督管理委员会</a>
                    &nbsp; <a href="http://amr.gd.gov.cn/" target="_blank">广东省市场监督管理局</a></div>
                </div>
              </div>
              <!--              <div class="flex-l aic" style="flex-direction: column;" v-if="webData.weixinPublicAccountQrCode">-->
              <!--                <img-->
              <!--                  :src="webData.weixinPublicAccountQrCode"-->
              <!--                  width="100"-->
              <!--                  height="100"-->
              <!--                  alt="">-->
              <!--                <div class="f-12 mt-5">{{ webData.weixinPublicAccountName }}</div>-->
              <!--              </div>-->
              <div class="flex-l aic" style="flex-direction: column;" v-if="webData.weixinVideoImageQrCode">
                <img
                  :src="webData.weixinVideoImageQrCode"
                  width="100"
                  height="100"
                  alt="">
                <div class="f-12 mt-5">微信视频号</div>
              </div>
              <div class="flex-l aic" style="flex-direction: column;" v-if="webData.xiaohongshuImageQrCode">
                <img
                  :src="webData.xiaohongshuImageQrCode"
                  width="100"
                  height="100"
                  alt="">
                <div class="f-12 mt-5">小红书</div>
              </div>
              <div class="flex-l aic" style="flex-direction: column;" v-if="webData.douyinImageQrCode">
                <img
                  :src="webData.douyinImageQrCode"
                  width="100"
                  height="100"
                  alt="">
                <div class="f-12 mt-5">抖音号</div>
              </div>
              <div class="flex-l aic" style="flex-direction: column;" v-if="webData.kuaishouImageQrCode">
                <img
                  :src="webData.kuaishouImageQrCode"
                  width="100"
                  height="100"
                  alt="">
                <div class="f-12 mt-5">快手</div>
              </div>
            </div>
            <div class="el-line"></div>
            <div class="friendly-link">
              <div class="item">友情链接</div>
              <div class="item" v-for="link in friendlyLinkList" :key="link.name">
                <a :href="link.linkUrl" target="_blank" v-if="link.linkUrl.length > 0">{{ link.name }}</a>
                <a href="#" @click.prevent v-else>{{ link.name }}</a>
              </div>
            </div>
            <div class="el-line"></div>
            <div class="friendly-link" style="height: 25px;">
              <div class="flex-l aic bei-wrap">
                <div class="item"><a
                  href="https://beian.miit.gov.cn/#/Integrated/index"
                  target="blank">{{ webData.icpRecordNumber }}</a></div>
                <div class="item"><a
                  @click="routerTo('/bayareacertification/appeals')">投诉申诉</a></div>
              </div>
            </div>
          </div>
        </div>
      </el-container>
    </div>
    <el-backtop target="" :visibility-height="500" :bottom="120" :right="13"></el-backtop>
  </div>
</template>

<script>
  import PAccountHeader from '@comp/project/account/PAccountHeader'
  import { getFriendlyLinksAll } from '@api/friendlyLinks'
  import { RECORD_NUMBER } from '@/store/mutation-types'
  import { getRecordNumberAll } from '@api/recordNumber'
  import { getWebsiteInfo } from '@api/websiteInfo'

  export default {
    name: 'PLayoutBase',
    components: { PAccountHeader },
    computed: {
      hasLogin() {
        return this.$store.state.user.token
      },
      paths() {
        return [
          {
            title: '首页',
            path: 'index'
          },
          {
            title: '中心资讯',
            path: 'caseCenter'
          },
          {
            title: '媒体报道',
            path: 'mediaCoverage'
          },
          {
            title: '认证申请',
            path: 'application'
          },
          {
            title: '认证查询',
            path: 'query'
          },
          {
            title: '认证标准',
            path: 'technicalnorm'
          },
          {
            title: '典型案例',
            path: 'classicCase'
          },
          // {
          //   title: '投诉申诉',
          //   path: '/bayareacertification/appeals'
          // },
          {
            title: '关于我们',
            path: 'aboutUs'
          }
        ]
      }
    },
    data() {
      return {
        isShowCourseWare: process.env.VUE_APP_IS_SHOW_COURSEWARE,
        activePath: '/bayareacertification/index',
        inputContent: '',
        friendlyLinkList: [],
        recordNumber: {},
        webData: {}
      }
    },
    created() {
      this.loadWebsiteData()
      this.initFriendLinks()
    // this.initRecordNumber()
    },
    watch: {
      '$route': {
        handler(newVal) {
          console.log(newVal)
          this.$nextTick(() => {
            this.$zh_tran(localStorage.getItem('lang'))
          })
          const exists = this.paths.findIndex((item) => newVal.path.includes(item.path))
          if (exists > -1) {
            this.activePath = newVal.path
          }
          this.$nextTick(() => {
            if (newVal.path === '/bayareacertification/index') {
              const navbar = document.getElementById('navbar')
              navbar.style.backgroundColor = '#7f7f7f10'
              window.addEventListener('scroll', function () {
                const scrollPosition = window.scrollY
                if (scrollPosition > 0) {
                  navbar.style.backgroundColor = '#228EAF'
                } else {
                  navbar.style.backgroundColor = '#7f7f7f10' // 滚回到顶部透明
                }
              })
            } else {
              const navbar = document.getElementById('navbar')
              navbar.style.backgroundColor = '#228EAF'
              window.addEventListener('scroll', () => {
                navbar.style.backgroundColor = '#228EAF'
              })
            }
          })
        },
        deep: true,
        immediate: true
      }
    },
    methods: {
      loadWebsiteData() {
        let data = {}
        getWebsiteInfo().then(res => {
          if (!res.success) {
            console.error(res.message)
            return
          }
          data = res.result[0]
        }).finally(() => {
          // 将数据存入vuex中
          this.$store.commit('WEB_DATA', data)
          this.webData = this.$store.state.webData
        })
      },
      initFriendLinks() {
        getFriendlyLinksAll().then(res => {
          if (!res.success) {
            console.error(res.message)
            return
          }
          this.friendlyLinkList = res.result
        })
      },
      initRecordNumber() {
        getRecordNumberAll().then(res => {
          if (!res.success) {
            console.error(res.message)
            return
          }
          this.recordNumber = res.result
          document.title = this.recordNumber.websiteName
        })
      },
      routerTo(params) {
        this.activePath = params
        this.$router.push(params)
      },
      loginTo() {
        this.$store.commit('SET_IS_SHOW_LOGIN', true)
      },
      changeLanguage(val) {
        console.log(val)
        this.lang = val
        if (val === 's') {
          localStorage.setItem('lang', 's')
        } else if (val === 't') {
          localStorage.setItem('lang', 't')
        }
        this.$zh_tran(localStorage.getItem('lang'))
        // 英文处理
        if (val === 'e') {
          localStorage.setItem('lang', 'e')
        }
      }
    }
  }
</script>

<style lang="less" scoped>
@import "~@/assets/styles/global.less";

/deep/ .el-dropdown {
  color: #FFFFFF;
}

.base-layout {
  min-width: 1920px;
  //顶部样式
  .fixed {
    width: 100%;
    position: sticky;
    top: 0;
    background: #7f7f7f10;
    z-index: 200;
    transition: 0.3s all;
    margin-bottom: -85px;
  }


  .head {
    position: relative;
    width: 1440px;
    height: 85px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-size: 100% 100%;
    //padding: 0 20px;
    color: #FFFFFF;

    .change-text {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      position: absolute;
      top: 10px;
      right: 0;
      cursor: pointer;
      transition: all .3s;

      &:hover {
        //color: @primary-color;
      }
    }

    .navBar {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 80px;

      span {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 18px;
        font-weight: bold;
        width: 130px;
        transition: all .3s;
      }

      span:hover {
        cursor: pointer;
        color: #ffffff50;
      }

      .active {
        color: #1320cc;
      }
    }

    .photo {
      position: absolute;
      display: flex;
      align-items: center;
      right: 0;

      span {
        padding-right: 15px;
        font-size: 18px;
        transition: all .3s;
      }

      span:hover {
        cursor: pointer;
        color: #578CEF;
      }
    }

    .login {
      position: absolute;
      right: 0;
      cursor: pointer;

      span {
        color: black;
        font-size: 16px;
        letter-spacing: 1px;
        transition: all .3s;
        //font-weight: bold;
      }

      span:hover {
        color: @primary-color;
      }
    }


    .top {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 3px;
      background-color: #FFFFFF;
    }

    .logo {
      width: 310px;
      height: 55px;
      background: url("~@/assets/index/headerLogo.png") no-repeat;
      background-size: 100% 100%;
    }

    .logo:hover {
      cursor: pointer;
    }
  }

  .container {
    min-height: calc(100vh - 268px);
  }

  //底部样式
  .bottom {
    .footer-wrap {
      width: 100%;
      padding: 48px 0;
      background: #141D32
    }

    .footer-wrap .footer-main {
      width: 1440px;
      margin: 0 auto;
      color: #ccc;
      font-size: 16px
    }

    .footer-wrap .footer-main .main-info {
      display: flex
    }

    .footer-wrap .footer-main .main-info .item-col {
      width: 800px;
      margin-bottom: 12px
    }

    .footer-wrap .footer-main .friendly-link {
      padding-top: 12px;
      display: flex;
      flex-wrap: wrap
    }

    .footer-wrap .footer-main .friendly-link .item:first-child {
      cursor: default
    }

    .footer-wrap .footer-main .friendly-link .item {
      cursor: pointer;
      font-size: 14px;
      margin-bottom: 15px;
      padding-right: 12px
    }

    .footer-wrap .footer-main .friendly-link .item a, .footer-wrap .footer-main a {
      font-size: 14px;
      color: #ccc;
      text-decoration: none
    }

    .el-line {
      width: 100%;
      height: 1px;
      background: rgba(204, 204, 204, .6)
    }

    .fl-sb {
      display: flex;
      justify-content: space-between
    }

    .fl-sa {
      display: flex;
      justify-content: space-around
    }

    .flex-r {
      display: flex;
      justify-content: flex-end
    }

    .flex-c {
      display: flex;
      justify-content: center
    }

    .flex-l {
      display: flex;
      margin-right: 20px
    }

    .aic {
      align-items: center
    }

    .fld-col {
      flex-direction: column
    }

    .fl-w {
      flex-wrap: wrap
    }

    .cursor-p {
      cursor: pointer
    }

    .cursor-not {
      cursor: not-allowed
    }

    .f-10 {
      font-size: 10px
    }

    .f-12 {
      font-size: 12px
    }

    .f-14 {
      font-size: 14px
    }

    .f-16 {
      font-size: 16px
    }

    .f-18 {
      font-size: 18px
    }

    .f-20 {
      font-size: 20px
    }

    .f-22 {
      font-size: 22px
    }

    .f-24 {
      font-size: 24px
    }

    .f-26 {
      font-size: 26px
    }

    .mt-4 {
      margin-top: 4px
    }

    .ma-5 {
      margin: 5px
    }

    .mt-5 {
      margin-top: 5px
    }

    .ml-5 {
      margin-left: 5px
    }

    .mr-5 {
      margin-right: 5px
    }

    .mb-5 {
      margin-bottom: 5px
    }

    .ma-10 {
      margin: 10px
    }

    .mt-10 {
      margin-top: 10px
    }

    .ml-10 {
      margin-left: 10px
    }

    .mr-10 {
      margin-right: 10px
    }

    .mb-10 {
      margin-bottom: 10px
    }

    .ma-20 {
      margin: 20px
    }

    .mt-20 {
      margin-top: 20px
    }

    .ml-20 {
      margin-left: 20px
    }

    .mr-20 {
      margin-right: 20px
    }

    .mb-20 {
      margin-bottom: 20px
    }

    .mt-12 {
      margin-top: 12px
    }

    .ml-12 {
      margin-left: 12px
    }

    .mr-12 {
      margin-right: 12px
    }

    .mb-12 {
      margin-bottom: 12px
    }

    .ma-15 {
      margin: 15px
    }

    .mt-15 {
      margin-top: 15px
    }

    .ml-15 {
      margin-left: 15px
    }

    .mr-15 {
      margin-right: 15px
    }

    .mb-15 {
      margin-bottom: 15px
    }

    #container {
      width: 100%;
      font-size: 15px;
      color: #7a7676;

      .connect {
        width: @banner-width;
        margin: 0 auto;
        display: flex;
        height: 50px;
        justify-content: flex-start;
        align-items: center;

        .hotlink {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-left: 45px;

          .a-href {
            padding-right: 45px;
          }

          a {
            font-size: 15px;
            color: #999;
            text-decoration: none;
          }
        }

        //height: 100px;
        //底部logo样式
        //.ft-left {
        //  width: 444px;
        //  float: left;
        //
        //  .logo {
        //    width: 100%;
        //    height: 55px;
        //    background: url("../../../assets/index/headerLogo-g2.png") no-repeat 0 0;
        //    background-size: contain;
        //  }
        //
        //  .introduction {
        //    display: inline-block;
        //    margin-top: 12px;
        //    font-size: 12px;
        //    color: #999;;
        //  }
        //}
        //
        //.ft-right {
        //  float: right;
        //
        //  h4 {
        //    font-size: 16px;
        //    margin-bottom: 5px;
        //    color: #ccc;
        //  }
        //
        //  .link-box {
        //    width: 400px;
        //
        //    .a-box {
        //      margin: 8px 0;
        //
        //      a {
        //        font-size: 12px;
        //        color: #999;
        //        text-decoration: none;
        //      }
        //
        //      a:hover {
        //        cursor: pointer;
        //        color: @primary-color;
        //      }
        //    }
        //  }
        //}
      }
    }

    .center {
      font-size: 14px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 12px;
      color: #999;

      .user-paper {
        margin-left: 8px;
        color: #999;
        cursor: pointer;

      }

      .user-paper:hover {
        cursor: pointer;
        opacity: .7;
      }

      .gabeian {
        display: flex;
        align-items: center;
        color: #999;

        &:hover {
          color: #0d308d;
        }
      }
    }
  }


}

/deep/ .el-loading-mask {
  // 加载的遮罩层减小层级
  z-index: 100;
}

</style>
